import { createAsyncThunk } from "@reduxjs/toolkit";
import { typedAdminAPI } from "../api/typedApi";
import { toast } from "react-toastify";

export const updateVoucher = createAsyncThunk(
  "admin/updateVoucher",
  async (
    voucherData: { isActive: boolean; id: number; name: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await typedAdminAPI.updateVoucher(voucherData);
      return response.data;
    } catch (error: any) {
      console.log(error);
      if (error.response.data.status === 404) {
        toast.error("Ваучер не найден", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        return rejectWithValue("Ваучер не найден");
      }
      return rejectWithValue(
        "Ой, что-то пошло не так, попробуйте обновить страницу"
      );
    }
  }
);

import { createAsyncThunk } from "@reduxjs/toolkit";
import { adminAPI } from "../api";
import { toast } from "react-toastify";

//будет один файл
export const createMarathon = createAsyncThunk(
  "admin/createMarathon",
  async (marathonInfoData: any, { rejectWithValue }) => {
    try {
      console.log(marathonInfoData);

      const { data } = await adminAPI.createMarathon(marathonInfoData);
      return data;
    } catch (error: any) {
      if (
        error.response.data.status === 400 &&
        error.response.data.errors[""][0] ===
          "Failed to read the request form. Request body too large. The max request body size is 30000000 bytes."
      ) {
        toast.error("Размер картинок не может быть больше 15 мегабайт", {
          position: "top-right",
          autoClose: 7000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        return rejectWithValue("Error");
      }
      toast.error("");

      return rejectWithValue("Error in creation a marathon");
    }
  }
);

export const updateMarathon = createAsyncThunk(
  "admin/updateMarathon",
  async (marathonInfoData: any, { rejectWithValue }) => {
    try {
      const data = await adminAPI.updateMarathon(marathonInfoData);
      return data.data;
    } catch (error: any) {
      if (
        error.response.data.status === 400 &&
        error.response.data.errors[""][0] ===
          "Failed to read the request form. Request body too large. The max request body size is 30000000 bytes."
      ) {
        toast.error("Размер картинок не может быть больше 15 мегабайт", {
          position: "top-right",
          autoClose: 7000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        return rejectWithValue("Error");
      }

      return rejectWithValue(error);
    }
  }
);

// export const updatePublicOffer = createAsyncThunk(
//   "admin/updatePublicOffer",
//   async (marathonInfoData: any,{rejectWithValue}) => {
//     try {
//       console.log(marathonInfoData)
//       const {data} = await adminAPI.updatePublicOffer(marathonInfoData);
//       return data;
//     } catch (error: any) {
//       if (error.response.data.status === 400 && error.response.data.errors[''][0] === 'Failed to read the request form. Request body too large. The max request body size is 30000000 bytes.') {
//         toast.error('Размер картинок не может быть больше 15 мегабайт', {
//           position: "top-right",
//           autoClose: 7000,
//           hideProgressBar: false,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//           progress: undefined,
//           theme: "colored",
//         });
//         return rejectWithValue('Error');
//       }
//
//       return rejectWithValue('Error in creation a marathon');
//     }
//   }
// )

export const getUserByAdmin = createAsyncThunk(
  "admin/getUserByAdmin",
  async (userId: number) => {
    try {
      const response = await adminAPI.getUserByAdmin(userId);
    } catch (error) {
      console.log("ERROR IN GET USER BY ADMIN", error);
    }
  }
);

export const getParticipantInfo = createAsyncThunk(
  "admin/getParticipantInfo",
  async (applicationId: number, { rejectWithValue }) => {
    try {
      const response = await adminAPI.getParticipantInfo(applicationId);
      return response.data;
    } catch (error: any) {
      if (error.response.data.status === 404) {
        return rejectWithValue("Not Found");
      }

      toast.error("Ой, что-то пошло не так, попробуйте обновить страницу", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return rejectWithValue("Error");
    }
  }
);
